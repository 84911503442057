// applicaiton management
const applicationInfo = '/ditf-trade-fair-management/application-info/'
export const receiveAppsList = applicationInfo + 'receive-list'
export const approveAppsApproveReturn = applicationInfo + 'approved-return'
export const approveAppsList = applicationInfo + 'approved-list'
export const stallCategoryList = applicationInfo + 'stall-category-list'
export const assignStallCategoryList = applicationInfo + 'assign-stall-category-list'
export const categoryWiseStallList = applicationInfo + 'category-wise-stall-list'
export const getAllApplicantApplyStall = applicationInfo + 'get-all-applicant-apply-stall'
export const getAllApplicantApplyCategory = applicationInfo + 'get-all-applicant-apply-category'
export const storeAssign = applicationInfo + 'assign-stall-to-applicant'
export const storeCategory = applicationInfo + 'assign-category-to-applicant'
// Return and  Refund
const returnRefund = '/ditf-circular-management/return-refund/'
export const returnRefundList = returnRefund + 'list'
export const returnRefundDataList = returnRefund + 'data-list'
export const DitfRefundStore = returnRefund + 'refund-store'
// Stall Booked List
export const stallBookedList = '/ditf-circular-management/stall-booked/list'
export const DitfApplicationDetails = '/ditf-trade-fair/fair-application/details'
