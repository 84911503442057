<template>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template>
            <b-overlay :show="loading">
              <div>
                <div class="application-form-wrapper application-form-view-wrapper" style="padding: 0 2rem;">
                  <div>
                    <h5 style="border-bottom: 2px solid #2f3542;width: 62%;margin: 2rem auto;padding-bottom: 5px;text-align: center;font-weight: 600">{{ $t('externalTradeFair.company_info_ditf') }}</h5>
                  </div>
                  <div class="application-itmes" v-if="detailsData">
                    <b-row>
                      <b-col sm="12">
                        <div class="jumbotron" style="padding: 1rem">
                          <p><b class="text-alt">{{ $t('tradeFairProposalManagement.circular') }} :</b> <span class="lead">{{ getSearchFairName(detailsData.fair_id) }}</span>
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <div v-if="detailsData">
                      <!-- {{ detailsData.stall_other }} -->
                      <div class="group-form-card">
                        <b-form-group label-cols-lg="3" :label="$t('externalTradeFair.company_basic_info')" label-size="md"
                          label-class="font-weight-bold pt-0" class="form-view-item">
                          <b-row>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('externalTradeFair.fair_name')} :`">
                                <p class="input-field">{{ getSearchFairName(detailsData.fair_id) }}
                                </p>
                              </b-form-group>
                            </b-col>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('globalTrans.application_id')} :`">
                                <p class="input-field">{{ detailsData.app_auto_id }}
                                </p>
                              </b-form-group>
                            </b-col>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('globalTrans.name')} :`">
                                <p class="input-field">{{ $i18n.locale === 'en' ? detailsData.name_en : detailsData.name_bn }}
                                </p>
                              </b-form-group>
                            </b-col>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('globalTrans.designation')} :`">
                                <p class="input-field">{{ $i18n.locale === 'en' ? detailsData.designation_en : detailsData.designation_bn }}
                                </p>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('globalTrans.name') + ' [' + $t('externalTradeFair.company_name') + ']'} :`">
                                <p class="input-field">{{ $i18n.locale === 'en' ? detailsData.comp_name_en : detailsData.comp_name_bn }}
                                </p>
                              </b-form-group>
                            </b-col>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('globalTrans.email')} :`">
                                <p class="input-field">{{ detailsData.email }}</p>
                              </b-form-group>
                            </b-col>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('globalTrans.fax')} :`">
                                <p class="input-field">{{ detailsData.fax }}</p>
                              </b-form-group>
                            </b-col>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('globalTrans.phone_no') + ' [' + $t('globalTrans.office') + ']'} :`">
                                <p class="input-field">{{ $n(0) + $n(detailsData.phone_no, { useGrouping: false }) }}</p>
                              </b-form-group>
                            </b-col>
                            <b-col sm="6">
                              <b-form-group :label="`${$t('globalTrans.mobile_no') + ' [' + $t('globalTrans.office') + ']'} :`">
                                <p class="input-field">{{ detailsData.mobile_no | mobileNumber }}</p>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                      <div v-if="detailsData.address" class="group-form-card">
                        <b-form-group label-cols-lg="3" :label="$t('globalTrans.org_address')" label-size="md"
                          label-class="font-weight-bold pt-0" class="form-view-item">
                          <b-row>
                            <b-col sm="12">
                              {{ $t('externalTradeFair.country_type') + ': ' + getCountryType(detailsData.address.country_type) }}
                            </b-col>
                            <b-col sm="12" v-if="detailsData.address.country_type === 1">
                                <p class="input-field">
                                {{ $t('globalTrans.address') + ': ' + AddressHelper.getCommonAddress(detailsData.address) + ' - ' + $n( detailsData.address.post_code , {useGrouping: false})}}
                                </p>
                            </b-col>
                            <b-col sm="12" v-if="detailsData.address.country_type === 2">
                                <p class="input-field">
                                  {{ $t('globalTrans.address') + ': ' + ($i18n.locale === 'bn' ? detailsData.address.city_bn : detailsData.address.city_en) + ', ' + ($i18n.locale === 'bn' ? detailsData.address.state_bn : detailsData.address.state_en) + (detailsData.address.post_code ? ' - ' + $n(detailsData.address.post_code, { useGrouping: false }) : '') }}
                                </p>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                      <div class="group-form-card" v-if="detailsData?.circular_type === 1">
                        <b-form-group label-cols-lg="3" :label="$t('externalTradeFair.type_of_company')" label-size="md"
                          label-class="font-weight-bold pt-0" class="form-view-item">
                          <b-row>
                            <b-col sm="6">
                              <span v-for="(itm, indx) in detailsData.business_type" :key="indx">
                                  <span>{{ getCompanyTypeName(itm.busin_type_id) }}</span><span v-if="indx+1 < detailsData.business_type.length">{{ ', ' }}</span>
                              </span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                      <!-- <b-col sm="6" v-if="detailsData?.business_type?.length > 0">
                              <b-form-group :label="`${$t('externalTradeFair.type_of_company')} :`">
                                <p class="input-field">{{ getCompanyTypeName(detailsData.business_type.busin_type_id) }}</p>
                              </b-form-group>
                            </b-col> -->
                      <div class="group-form-card" v-if="detailsData?.circular_type === 1">
                        <b-form-group label-cols-lg="3" :label="$t('externalTradeFair.products_to_displayed')" label-size="md"
                          label-class="font-weight-bold pt-0" class="form-view-item"  v-if="detailsData?.disp_products?.length > 0">
                          <b-row>
                            <b-col sm="6">
                              <span v-for="(itm, indx) in detailsData.disp_products" :key="indx">
                                  <span>{{ $i18n.locale === 'en' ? itm.prod_name_en : itm.prod_name_bn }}</span><span v-if="indx+1 < detailsData.disp_products.length">{{ ', ' }}</span>
                              </span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                    </div>
                    <div v-if="detailsData?.owner_officer?.length > 0">
                      <div class="group-form-card">
                        <b-form-group label-cols-lg="3" :label="$t('externalTradeFair.org_officer_info')" label-size="md"
                          label-class="font-weight-bold pt-0" class="form-view-item">
                          <b-row>
                            <b-col sm="12">
                              <b-table-simple bordered small>
                                <thead>
                                  <tr class="text-align-center">
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.sl_no') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.name') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('sideBar.officer') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.designation') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.address') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.phone_no') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.mobile_no') }}</th>
                                  </tr>
                                </thead>
                                <b-tbody v-if="detailsData?.owner_officer?.length > 0">
                                  <b-tr v-for="(item, index) in detailsData.owner_officer.filter(item => item.info_type === 2)" :key="index">
                                    <b-td>{{ $n(index + 1) }}</b-td>
                                    <b-td>{{ $i18n.locale === 'en' ? item.name_en : item.name_bn }}</b-td>
                                    <b-td>{{ getOfficerType(item.info_type) }}</b-td>
                                    <b-td>{{ $i18n.locale === 'en' ? item.designation_en : item.designation_bn }}</b-td>
                                    <b-td>{{ $i18n.locale === 'en' ? item.address_en : item.address_bn }}</b-td>
                                    <b-td>{{ $n(0) + $n(item.phone_no, { useGrouping: false }) }}</b-td>
                                    <b-td>{{ item.mobile_no | mobileNumber }}</b-td>
                                  </b-tr>
                                </b-tbody>
                              </b-table-simple>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                    </div>
                    <div>
                      <div class="group-form-card">
                        <b-form-group label-cols-lg="3" :label="$t('externalTradeFair.org_owner_info')" label-size="md"
                          label-class="font-weight-bold pt-0" class="form-view-item">
                          <b-row>
                            <b-col sm="12">
                              <b-table-simple bordered small>
                                <thead>
                                  <tr class="text-align-center">
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.sl_no') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.name') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('sideBar.officer') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.designation') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.address') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.phone_no') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.mobile_no') }}</th>
                                  </tr>
                                </thead>
                                <b-tbody v-if="detailsData?.owner_officer?.length > 0">
                                  <b-tr v-for="(item, index) in detailsData.owner_officer.filter(item => item.info_type === 1)" :key="index">
                                    <b-td>{{ $n(index + 1) }}</b-td>
                                    <b-td>{{ $i18n.locale === 'en' ? item.name_en : item.name_bn }}</b-td>
                                    <b-td>{{ getOfficerType(item.info_type) }}</b-td>
                                    <b-td>{{ $i18n.locale === 'en' ? item.designation_en : item.designation_bn }}</b-td>
                                    <b-td>{{ $i18n.locale === 'en' ? item.address_en : item.address_bn }}</b-td>
                                    <b-td>{{ $n(0) + $n(item.phone_no, { useGrouping: false }) }}</b-td>
                                    <b-td>{{ item.mobile_no | mobileNumber }}</b-td>
                                  </b-tr>
                                </b-tbody>
                              </b-table-simple>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                    </div>
                    <div v-if="detailsData?.circular_type === 1 && detailsData?.stall_pavilion?.length > 0">
                      <div class="group-form-card">
                        <b-form-group label-cols-lg="3" :label="$t('tradeFairConfig.stall_information')" label-size="md"
                          label-class="font-weight-bold pt-0" class="form-view-item">
                          <b-row>
                            <b-col sm="12">
                              <b-table-simple bordered small>
                                <thead>
                                  <tr class="text-align-center">
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.sl_no') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('ditfConfig.stall_no') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('tradeFairConfig.stall_size') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('ditfConfig.fare_floor') + ' ' + $t('globalTrans.tk') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('externalTradeFair.quoted_price') + ' ' + $t('globalTrans.tk') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('ditfConfig.stall_location') }}</th>
                                  </tr>
                                </thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in detailsData.stall_pavilion" :key="index">
                                      <b-td>{{ $n(index + 1) }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? item.stall.stall_cat_detail.cat_name_en : item.stall.stall_cat_detail.cat_name_bn }}</b-td>
                                      <b-td>{{ $n(item.stall.stall_no) }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? item.stall.stall_cat_detail.size_en : item.stall.stall_cat_detail.size_en }}</b-td>
                                      <b-td>{{ $n(item.stall.stall_cat_detail.floor_price) }}</b-td>
                                      <b-td>{{ $n(item.quoted_price) }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? item.stall.stall_location_en : item.stall.stall_location_en }}</b-td>
                                    </b-tr>
                                </b-tbody>
                              </b-table-simple>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                    </div>
                    <div v-if="detailsData?.circular_type === 2 && detailsData?.stall_other?.length > 0">
                      <div class="group-form-card">
                        <b-form-group label-cols-lg="3" label-size="md" :label="$t('tradeFairConfig.stall_information')"
                          label-class="font-weight-bold pt-0" class="form-view-item">
                          <b-row>
                            <b-col sm="12">
                              <b-table-simple bordered small>
                                <thead>
                                  <tr class="text-align-center">
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.sl_no') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('tradeFairConfig.stall_size') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('ditfConfig.fare_floor') + ' ' + $t('globalTrans.tk') }}</th>
                                    <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('externalTradeFair.quoted_price') + ' ' + $t('globalTrans.tk') }}</th>
                                  </tr>
                                </thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in detailsData.stall_other" :key="index">
                                      <b-td>{{ $n(index + 1) }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? item.stall_info.cat_name_en : item.stall_info.cat_name_bn }}</b-td>
                                      <b-td>{{ $i18n.locale === 'en' ? item.stall_info.size_en : item.stall_info.size_en }}</b-td>
                                      <b-td>{{ $n(item.stall_info.floor_price) }}</b-td>
                                      <b-td>{{ $n(item.quoted_price) }}</b-td>
                                    </b-tr>
                                </b-tbody>
                              </b-table-simple>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                    </div>
                    <div>
                      <div class="group-form-card" v-if="detailsData.attachments">
                        <b-form-group label-cols-lg="3" :label="$t('externalTradeFair.docs_to_be_attached')" label-size="md"
                          label-class="font-weight-bold pt-0" class="form-view-item">
                          <b-row>
                            <b-col sm="6">
                              <ul>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(1) }}. {{ $t('externalTradeFair.applicant_photo') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.applicant_photo"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.applicant_photo">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(2) }}. {{ $t('externalTradeFair.trade_license') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.trade_license"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.trade_license">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(3) }}. {{ $t('externalTradeFair.vat_regi_certificate') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.vat_regi_certificate"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.vat_regi_certificate">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(4) }}. {{ $t('externalTradeFair.export_regi_certificate') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.export_regi_certificate"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.export_regi_certificate">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(5) }}. {{ $t('externalTradeFair.prc_certificate') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.prc_certificate"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.prc_certificate">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(6) }}. {{ $t('externalTradeFair.tax_certificate') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.tax_certificate"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.tax_certificate">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(7) }}. {{ $t('externalTradeFair.chamber_certificate') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.chamber_certificate"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.chamber_certificate">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(8) }}. {{ $t('externalTradeFair.bank_certificate') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.bank_certificate"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.bank_certificate">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(9) }}. {{ $t('externalTradeFair.nid_attachment') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.nid_attachment"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.nid_attachment">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div class="d-flex justify-content-between">
                                    <span style="font-size: 1.06rem;">{{ $n(10) }}. {{ $t('globalTrans.signature') }}</span>
                                    <div class="list-btn">
                                      <div>
                                        <!-- <i class="ri-check-line yes-btn"></i> -->
                                        <a target="_blank" v-if="detailsData.attachments.signature"
                                          :href="baseUrl + 'storage/' + detailsData.attachments.signature">
                                          <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import AddressHelper from '@/utils/area-type-address'
import { DitfApplicationDetails } from '../api/routes'
// import ListReportHead from '@/components/custom/ListReportHead.vue'
export default {
    name: 'Details',
    props: ['id', 'circular_type', 'item'],
    components: {
      // ListReportHead
    },
    data () {
      return {
        AddressHelper: AddressHelper,
        baseUrl: internationalTradeFairServiceBaseUrl,
        loading: false,
        detailsData: [],
        stallCategoryList: [],
        circular: {},
        stallList: [],
        stalls: [],
        countryTypeList: [
        {
          value: 1,
          text: 'Local',
          text_en: 'Local',
          text_bn: 'স্থানীয়'
        },
        {
          value: 2,
          text: 'Foreign',
          text_en: 'Foreign',
          text_bn: 'বিদেশী'
        }
        ],
        officerTypeList: [
        {
          value: 1,
          text: 'Owner',
          text_en: 'Owner',
          text_bn: 'মালিক'
        },
        {
          value: 2,
          text: 'Officer',
          text_en: 'Officer',
          text_bn: 'অফিসার'
        }
        ],
        circular_name: '',
        fair_name: ''
      }
    },
    created () {
      if (this.id) {
        this.loading = true
        this.getParticipationInfo()
      }
    },
    methods: {
      getCountryType (id) {
        const countryType = this.countryTypeList.find(obj => obj.value === parseInt(id))
        if (typeof countryType !== 'undefined') {
          return this.$i18n.locale === 'en' ? countryType.text_en : countryType.text_bn
        } else {
          return ''
        }
      },
      getCompanyTypeName (id) {
        const companyType = this.$store.state.TradeFairService.ditfCommonObj.businessTypeList.find(obj => obj.value === parseInt(id))
        if (typeof companyType !== 'undefined') {
          return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
        } else {
          return ''
        }
      },
      getCompanyNatureName (id) {
        const companyNature = this.$store.state.TradeFairService.commonObj.companyNatureList.find(obj => obj.value === parseInt(id))
        if (typeof companyNature !== 'undefined') {
          return this.$i18n.locale === 'en' ? companyNature.text_en : companyNature.text_bn
        } else {
          return ''
        }
      },
      getMeasurementUnitName (Id) {
        const Obj = this.$store.state.TradeFairService.commonObj.measurementUnitList.find(item => item.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
          return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
        }
        return ''
      },
      countryName (Id) {
        const Obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
            return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
        } else {
          return ''
        }
      },
      nationalityName (Id) {
        const Obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
          return this.$i18n.locale === 'bn' ? Obj.nationality_bn : Obj.nationality
        } else {
          return ''
        }
      },
      async getParticipationInfo () {
        const resultData = await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfApplicationDetails, { app_id: this.id })
        if (resultData.success) {
          this.detailsData = resultData.data
          this.loading = false
        } else {
          this.detailsData = []
          this.loading = false
        }
      },
      getCountry (id) {
        const sectorObj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(id))
        if (sectorObj !== undefined) {
          return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
        }
      },
      getDivision (id) {
        const sectorObj = this.$store.state.CommonService.commonObj.divisionList.find(el => el.value === parseInt(id))
        if (sectorObj !== undefined) {
          return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
        }
      },
      getDistrict (id) {
        const sectorObj = this.$store.state.CommonService.commonObj.districtList.find(el => el.value === parseInt(id))
        if (sectorObj !== undefined) {
          return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
        }
      },
      getCityCorporation (id) {
        const sectorObj = this.$store.state.CommonService.commonObj.cityCorporationList.find(el => el.value === parseInt(id))
        if (sectorObj !== undefined) {
          return this.$i18n.locale === 'bn' ? sectorObj.text_bn : sectorObj.text_en
        }
      },
      getStallCategoryName (id) {
        const Obj = this.stallCategoryList.find(item => item.value === id)
        if (this.currentLocale === 'bn') {
          return Obj !== undefined ? Obj.text_bn : ''
        } else {
          return Obj !== undefined ? Obj.text_en : ''
        }
      },
      getStallType (id) {
        const dataStallType = this.$store.state.TradeFairService.commonObj.stallTypeList.find(item => item.value === parseInt(id))
        if (typeof dataStallType !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataStallType.text_en : dataStallType.text_bn
        } else {
          return ''
        }
      },
      getOfficerType (id) {
        const dataStallType = this.officerTypeList.find(item => item.value === parseInt(id))
        if (typeof dataStallType !== 'undefined') {
          return this.$i18n.locale === 'en' ? dataStallType.text_en : dataStallType.text_bn
        } else {
          return ''
        }
      },
      async pdfExport () {
        this.loading = true
        const params = {
          app_id: this.id,
          circular_type: this.circular_type,
          request_type: 'pdf',
          local: this.$i18n.locale,
          org_id: 2
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, DitfApplicationDetails, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loading = false
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getSearchFairName (id) {
        const companyType = this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
        if (typeof companyType !== 'undefined') {
          return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
        } else {
          return ''
        }
      }
    }
}
</script>
<style>
.download-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 1px 3px;
}

.yes-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 700;
  padding: 1px 3px;
}
</style>
